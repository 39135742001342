html[data-global-display-mode="square"],
html[data-global-display-mode="condensed-square"] {
  --border-radius-base: 2px;
}

html[data-global-display-mode="condensed-round"] {
  --card-border-radius: 7px;
}

html[data-global-display-mode="condensed-round"],
html[data-global-display-mode="condensed-square"] {
  --block-spacing: 12px;
  --kpi-icon-font-size: 3rem;
  --kpi-number-font-size: 2.2rem;
  --table-inner-padding-h: 2px;
  --table-inner-padding: 2.5px var(--table-inner-padding-h);
  --btns-margin: 2px;
  --card-border: 1px solid var(--card-border-color);
  --card-header-btn-min-height: 26px;
  --app-controls-dropdown-border-radius: 2px;
  --app-controls-dropdown-item-height: 31px;
  --app-controls-dropdown-icon-size: 22px;
  --nav-controls-width: 240px;
  --nav-controls-link-margin-bottom: 0;
  --nav-controls-link-margin-h: 8px;
  --nav-controls-link-margin: 0px var(--nav-controls-link-margin-h);
  --nav-controls-link-padding-h: 16px;
  --nav-controls-link-padding-v: 6px;
  --nav-controls-link-icon-font-size: 2.2rem;
  --nav-controls-line-v-left: 32px;

  .checkbox-align .checkbox {
    padding-top: 3px;
  }

  .documentation-page,
  .api-documentation-page {

    h2,
    h3,
    h4,
    h5 {
      margin: calc(2 * var(--block-spacing)) 0 var(--block-spacing);
    }
  }
}

html[data-global-display-mode="aerated-round"] {
  --block-spacing: 18px;
  --card-border-radius: 7px;
  --card-title-col-min-height: 36px;
  --card-content-padding: 18px;
  --app-controls-search-form-border-radius: 7px;

  @media (max-width: $screen-sm) {
    --block-spacing: 15px;
    --card-content-padding: 15px;
  }
}

html[data-buttons-display-mode="big-round"] {
  --btn-border-radius: 7px;
}

html[data-buttons-display-mode="square"] {
  --btn-border-radius: 2px;
}

html[data-no-decoration="true"] {
  .pages-layout {
    margin: 0 !important;
    width: 100%;

    .app-controls-wrapper,
    .nav-controls-wrapper {
      display: none;
    }
  }
}

html[data-table-status-badge-display-mode="bullet"] {

  .card-table .btn-badge,
  table .btn-badge {
    align-items: center;
    background: transparent !important; // there's somewhat a glitch here with the bootstrap css that forces us to use !important
    border: 0;
    color: var(--text-color) !important; // same as commented before for the background property
    display: flex;
    font-weight: bold;
    line-height: 1;
    padding-left: 15px;
    position: relative;
    text-align: left;
    white-space: normal;

    &::before {
      border-radius: 50%;
      content: "";
      position: absolute;
      left: 0;
      height: 10px;
      width: 10px;
    }

    &.btn::before {
      background: var(--color-black-lighter);
    }

    &.btn-primary::before {
      background: var(--primary-color);
    }

    &.btn-info::before {
      background: var(--color-blue);
    }

    &.btn-success::before {
      background: var(--color-green);
    }

    &.btn-warning::before {
      background: var(--warning-color);
    }

    &.btn-danger::before {
      background: var(--color-red);
    }

    &.btn-green::before {
      background: var(--btn-green);
    }

    &.btn-yellow::before {
      background: var(--btn-yellow);
    }

    &.btn-orange::before {
      background: var(--btn-orange);
    }

    &.btn-red::before {
      background: var(--btn-red);
    }

    &.btn-purple::before {
      background: var(--btn-purple);
    }

    &.btn-blue::before {
      background: var(--btn-blue);
    }

    &.btn-gray::before {
      background: var(--btn-gray);
    }

    &.btn-neutral::before {
      background: var(--btn-neutral);
    }

    &.active,
    &.focus,
    &:hover {
      background: transparent;
      color: var(--text-color);
    }
  }
}

html[data-kpi-display-mode="inverted"] {
  .stats-card {
    .big-icon i {
      border-radius: 50%;
      font-size: 3.6rem;
      min-height: 44px;
      min-width: 44px;
      padding: 4px;

      &.text-default {
        background: var(--color-gray-lighter);
      }

      &.text-primary {
        background: var(--primary-background-color);
      }

      &.text-info {
        background: var(--info-background-color);
      }

      &.text-success {
        background: var(--success-background-color);
      }

      &.text-warning {
        background: var(--warning-background-color);
      }

      &.text-danger {
        background: var(--danger-background-color);
      }

      &.text-muted {
        background: var(--muted-background-color);
      }
    }

    .numbers {
      color: var(--text-color);
      display: flex;
      flex-direction: column-reverse;
      text-align: left;

      .kpi-value {
        font-weight: bold;
      }
    }
  }
}

html[data-border-style="borderless"] {
  --card-box-shadow: unset;
  --card-border: 0;
  --app-controls-border-bottom: 0;
  --nav-controls-logo-partner-or-tenant-height: var(--app-controls-height);

  &[data-entity-workflow-card-position="sticky"] {
    @media (min-width: $screen-md) {
      .container {
        >.entity-workflow-card {
          border-top: 1px solid var(--card-border-color);
        }
      }
    }
  }
}

html[data-input-style="border-bottom-only"] {
  .pseudo-select-control {
    .search-input-icon {
      left: 0;
    }

    .form-control {
      padding-left: calc(var(--icn-md) + 2px);
    }
  }

  input {
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    border-top: 0;
    padding: 0;

    &:focus {
      border-left: 0;
      border-right: 0;
      border-top: 0;
    }
  }

  textarea {
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    border-top: 0;
    padding-left: 0;
    padding-right: 0;

    &:focus {
      border-left: 0;
      border-right: 0;
      border-top: 0;
    }
  }

  .select-group,
  .form-control {
    background-color: transparent;
    border-radius: 0 !important;
  }

  .input-group-addon {
    background: transparent;
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    border-top: 0;
    padding-left: 0;
  }

  .form-control[disabled],
  .form-control[readonly],
  fieldset[disabled] .form-control,
  .input-group[data-disabled="true"] .input-group-addon {
    background-color: var(--color-gray-lightest);
  }

  .input-group:not([data-disabled=true]) .input-group-addon {
    padding-right: var(--input-addon-h-padding);

    +.form-control[disabled] {
      border-left: 0;
    }
  }

  .input-group .input-group-addon+.select-group .select__control,
  .input-group .select-group:first-child:not(:last-child) .select__control {
    border-radius: 0;
  }

  .select-group .select__control {
    background: transparent;
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    border-top: 0;
    padding: 0;

    .select__single-value {
      margin: 0;
      padding: 0;
    }

    &.select__control--is-disabled {
      background-color: var(--muted-background-color);
      padding: 0 var(--input-h-padding);
    }
  }

  .select__placeholder,
  .select__input-container {
    margin-left: 0;
    margin-right: 0;
  }

  .select-group .select__multi-value {
    margin-left: 0;
  }

  .select__option {
    padding: 8px;
  }
}

html[data-authentication-contrast="dark"] {
  .mfa-verification-component {
    input {
      &::placeholder {
        color: transparent !important;
      }
    }
  }

  .authentication-layout {

    a,
    p,
    div,
    label,
    input,
    button:not(:disabled),
    .checkbox label::before {
      color: var(--color-white) !important;
    }

    .text-link {
      text-decoration: underline;
    }

    .login-page,
    .mfa-initialization-page {
      input {
        &::placeholder {
          color: transparent !important;
        }
      }
    }

    .input-group-addon {
      background: transparent;
      border-radius: 0;
      border-left: 0;
      border-right: 0;
      border-top: 0;
    }

    input {
      background: transparent;
      border-color: var(--color-white);
      border-radius: 0;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      box-shadow: 0 0 0 30px var(--authentication-form-background-color) inset !important;
      color: var(--color-white) !important;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-text-fill-color: var(--color-white) !important;
      }
    }
  }
}

html[data-authentication-contrast="transparent"] {
  .mfa-verification-component {
    input {
      background: transparent;

      &::placeholder {
        color: transparent !important;
      }
    }
  }

  .authentication-layout {
    a,
    p,
    div,
    label,
    input,
    button:not(:disabled),
    .checkbox label::before {
      color: var(--color-white) !important;
    }

    .form-group:focus-within .form-control {
      border-color: var(--color-white);
    }

    .text-link {
      text-decoration: underline;
    }

    .login-page,
    .change-password-page,
    .mfa-initialization-page {
      input {
        background: transparent;

        &::placeholder {
          color: transparent !important;
        }
      }
    }

    .card {
      box-shadow: none;
    }
  }

  @media (max-width: $screen-xs) {
    .authentication {
      background: unset;
      background-image: unset;
    }
  }
}

html[data-authentication-disposition] {
  .authentication-layout {
    height: 100vh;
    width: 100vw;

    .authentication {
      height: 100%;

      form {
        height: 100%;
        max-width: unset;
      }

      .card {
        border-radius: 0;
        height: 100%;
        padding: 30px 50px 0;
        max-width: 460px;
        overflow-y: auto;
        width: 100vw;
      }

      &.register-page {
        float: unset !important;

        .container {
          overflow-y: auto;

          .card {
            padding: 0;
          }
        }
      }
    }

    @media (max-width: $screen-xs) {
      .authentication {
        .card {
          padding: 0 15px;
        }
      }
    }
  }
}

html[data-authentication-disposition="left"] {
  .authentication {
    float: left;
  }
}

html[data-authentication-disposition="right"] {
  .authentication {
    float: right;
  }
}

html[data-nav-controls-links-display-mode="full"] {
  --nav-controls-line-v-left: 30px;

  @media (max-width: $screen-sm) {
    .app-controls-wrapper .app-menu .app-menu-list {
      padding-top: 0;
    }
  }

  .nav-controls {
    padding-top: 0;

    .nav-control,
    .nav-subcontrol {
      border-radius: 0;
      margin: 0;
    }
  }
}

html[data-nav-controls-display-mode="top"] {
  .nav-controls-subwrapper,
  .app-controls-wrapper {
    border-color: transparent;
  }

  .nav-divider {
    display: none;
  }

  @media (min-width: $screen-sm-1) {
    body {
      height: calc(100% - var(--app-controls-height));
      margin-top: var(--app-controls-height);
      padding-top: 0;
    }

    .pages-layout {
      margin-left: 0;
      width: 100%;
    }

    .partner-or-tenant-logo {
      z-index: 1020;
    }

    .actual-tenant-logo {
      background: var(--nav-controls-logo-partner-or-tenant-background);
      height: var(--app-controls-height);
      left: var(--nav-controls-width);
      margin: 0;
      object-fit: contain;
      overflow-x: hidden;
      padding: 0;
      position: fixed;
      text-align: center;
      top: 0;
      width: var(--nav-controls-width);
      z-index: 1020;
    }

    .app-controls-wrapper {
      background: var(--nav-controls-background);
      justify-content: flex-end;
      position: fixed;
      right: 0;
      top: 0;
      width: calc(var(--nav-controls-width) + (var(--control-btn-hw) * var(--data-offset-multiplier)));
      z-index: 10001; // must go over the side view

      .app-controls {
        flex-grow: unset;
      }

      .app-control-btn i {
        color: var(--nav-controls-text-color);
      }

      .user-block {

        .user-block-wrapper,
        .user-avatar {
          color: var(--nav-controls-text-color);
        }

        .user-avatar {
          border-color: var(--nav-controls-text-color);
        }

        .orbiting-point {
          background-color: var(--nav-controls-text-color);
        }
      }
    }

    &[data-nav-controls-logo-tenant-display-mode="hideAtTopWhenHasPartnerLogo"] {
      .actual-tenant-logo {
        display: none;
      }
    }

    &:not([data-nav-controls-logo-tenant-display-mode="hideAtTopWhenHasPartnerLogo"]) {
      .nav-controls-subwrapper[data-has-partner-logo="true"] {
        .nav-controls {
          margin-left: var(--nav-controls-width);
          width: calc(100% - var(--nav-controls-width) - (var(--control-btn-hw) * var(--data-offset-multiplier)));
        }

        .overflow-arrow-left {
          left: var(--nav-controls-width);
        }
      }
    }
    
    .nav-controls-subwrapper {
      left: var(--nav-controls-width);
      top: 0;
      height: var(--app-controls-height);
      overflow: unset;
      width: calc(100% - (var(--nav-controls-width) * 2) - (var(--control-btn-hw) * var(--data-offset-multiplier)));
      z-index: 1010;

      .nav-controls {
        display: flex;
        overflow: unset;
        padding: 0;
        transition: transform 1s ease;
        width: 100%;

        .nav-block {
          overflow: unset;
          position: relative;
          width: auto;

          &.nav-block-hover {
            .nav-control {
              text-decoration: underline;
            }

            .nav-subcontrols {
              border-top-left-radius: 0;
              border-top-right-radius: 0;
              display: block;

              &[data-columns-threshold="true"] {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                max-height: calc(100vh - var(--app-controls-height));
                overflow-y: auto;
              }

              .nav-subcontrol:hover {
                text-decoration: underline;
              }
            }
          }

          i,
          b.caret {
            display: none;
          }
        }

        .nav-control {
          align-items: center;
          border-radius: 0;
          display: flex;
          font-weight: normal;
          height: 100%;
          margin: 0;
          padding: 0 var(--block-spacing);
          text-transform: none;
        }

        .nav-text {
          flex-grow: unset;
          font-size: var(--font-size-base);
          min-width: unset;
          padding-left: 0;
          white-space: nowrap;

          &::after {
            display: none;
          }
        }

        .nav-subcontrols {
          background: var(--nav-controls-background);
          border: var(--card-border);
          border-radius: var(--nav-controls-link-border-radius);
          border-top: 0;
          display: none;
          left: 0;
          // when not in this theme, the height is set by the js code
          // for toggling between the collapsed / expanded states
          // we override this behavior by setting the !important flag
          height: auto !important;
          overflow: hidden;
          position: absolute;
          top: var(--app-controls-height);
        }

        .nav-subcontrol {
          border-radius: 0;
          break-inside: avoid;
          margin: 0;
          opacity: 1;

          &::before,
          &::after {
            display: none;
          }
        }
      }

      .overflow-arrow-left,
      .overflow-arrow-right {
        align-items: center;
        background: var(--nav-controls-background);
        border: 0;
        color: var(--nav-controls-text-color);
        cursor: pointer;
        display: flex;
        height: 100%;
        justify-content: center;
        opacity: 0;
        padding: 0;
        pointer-events: none;
        position: absolute;
        transition: opacity 0.2s;
        width: 30px;
        z-index: 10;

        i {
          align-items: center;
          border: 0;
          border-color: var(--nav-controls-text-color);
          border-style: solid;
          display: flex;
          font-size: 1.2rem;
          margin: 0;
        }

        &[data-show-overflow-arrow="true"] {
          opacity: 1;
          pointer-events: all;
        }
      }

      .overflow-arrow-left {
        left: 0;

        i {
          border-right-width: 1px;
          padding-right: 4px;
        }
      }

      .overflow-arrow-right {
        right: 0;

        i {
          border-left-width: 1px;
          padding-left: 4px;
        }
      }
    }
  }
}

html[data-entity-workflow-card-position="sticky"] {
  @media (min-width: $screen-md) {
    .container {
      >.entity-workflow-card {
        background: var(--color-white);
        box-shadow: var(--card-box-shadow);
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: var(--card-margin-bottom);
        margin-left: calc(-1 * var(--block-spacing));
        margin-top: calc(-1 * var(--block-spacing));
        padding: 0;
        position: sticky;
        top: 0;
        width: calc(100% + 2 * var(--block-spacing));
        z-index: 1001;

        >div:nth-child(1) {
          flex-grow: 1;
          margin: 0;
          padding: 0;
          width: unset;

          .card {
            border: 0;
            border-radius: 0;
            box-shadow: unset;
            margin-bottom: 0;
          }
        }
      }
    }

    .form-bullet-summary {
      ul {
        columns: var(--form-bullet-summary-columns-nb);
      }
    }

    &[data-global-display-mode="condensed-square"] {
      .container {
        >.entity-workflow-card {
          border-bottom: var(--card-border);

          >div:nth-child(1) {
            .card {
              border-left: 0;
              border-right: 0;
            }
          }
        }
      }
    }
  }
}

html[data-app-controls-display-mode="condensed"] {
  --app-controls-height: 40px;
}

html[data-nav-controls-link-active-display-mode="emphasis"] {
  .nav-controls {

    .nav-control,
    .nav-subcontrol {
      border-left: 3px solid transparent;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;

      &:hover,
      &.active {
        border-left-color: var(--nav-controls-link-hover-emphasis-border-color,
            var(--nav-controls-link-active-text-color, var(--nav-controls-text-color)));
      }
    }
  }

  .navs-card {
    .nav {
      border-left-width: 3px;

      &[data-active-nav="true"] {
        border-left-color: var(--nav-controls-link-hover-emphasis-border-color,
            var(--nav-controls-link-active-text-color, var(--nav-controls-text-color)));
      }
    }

    .nav i {
      font-size: 2rem;
      width: 80px;
    }

    &[data-display-mode="tabs"],
    &[data-display-mode="list"] {
      .nav i {
        width: auto;
      }
    }
  }
}